import { NavLink } from "react-router-dom";
import { StyledFooter, Logo, Nav, Copyright } from "./Footer.style";
// import { FaFacebookF } from "react-icons/fa";
import { MenuList } from "../../utils/MenuList";
import STARLogo from "../../images/starLogo.svg";

const Footer = () => {
  return (
    <StyledFooter>
      <Logo>
        <img src={STARLogo} alt="" />
      </Logo>
      <Copyright>
        <p>
          Copyright &#169; {new Date().getFullYear()} - STAR Sacramento <br />
          A Division Of <a href="https://starinc.org" rel="noreferrer" target="_blank">STAR Inc.</a>
        </p>
      </Copyright>
      {/* <Social>
        <a
          href="https://www.facebook.com/stareureka?ref=bookmarks"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
          <span>Eureka USD STAR Sites</span>
        </a>
        <a
          href="https://www.facebook.com/pages/STAR-Education-Loomis-Union-School-District/822676291086522?ref=bookmarks"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
          <span>Loomis USD STAR Sites</span>
        </a>
        <a
          href="https://www.facebook.com/pages/STAR-Education-Western-Placer-Unified-School-District/363117300511556?ref=bookmarks"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
          <span>Western Placer USD STAR Sites</span>
        </a>
      </Social> */}
      <Nav>
        <ul>
          {MenuList.map((link, index) => (
            <NavLink key={index} to={link.url} exact activeClassName="current">
              <li>{link.title}</li>
            </NavLink>
          ))}
        </ul>
      </Nav>
    </StyledFooter>
  );
};

export default Footer;
