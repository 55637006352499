import styled from "styled-components";

export const CTA = styled.div`
  /* border: 3px solid ${({ theme }) => theme.secondaryColor}; */
  box-shadow: 0 0 12px ${({ theme }) => theme.sectionAccent};
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  margin: ${({ theme }) => theme.pdDefault};
  font-family: ${({ theme }) => theme.fntSans};

  p {
    padding-inline: ${({ theme }) => theme.pdDefault};
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fntSerif};
  color: ${({ theme }) => theme.primaryColor};
  font-size: clamp(1.5rem, 1.75rem, 2rem);
  padding: ${({ theme }) => theme.pdDefault};
`;

export const Info = styled.div`
  padding: ${({ theme }) => theme.pdDefault};
`;

export const Date = styled.div`
  padding: ${({ theme }) => theme.pdDefault};
`;
