// im  port { Link } from "react-router-dom";
import Button from "../Button/Button";
import { CTA, Title, Info, Date } from "./CTA.style";

const CallToAction = ({
  title,
  info,
  date,
  btnText,
  url,
  disabled,
  small,
  medium,
  green,
  orange,
  hidden,
  children,
}) => {
  return (
    <CTA>
      <Title>{title}</Title>
      {children}
      <Info>{info}</Info>
      <Date>{date}</Date>
      <a href={url} target="_blank" rel="noreferrer">
        <Button
          disabled={disabled}
          small={small}
          medium={medium}
          green={green}
          orange={orange}
          hidden={hidden}
        >
          {btnText}
        </Button>
      </a>
    </CTA>
  );
};

export default CallToAction;
