import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import interactionPlugin from "@fullcalendar/interaction";
import { Popover } from "bootstrap";
import { CalWrapper } from "./Calendar.style";

let popoverInstance = null;

// const API_KEY = "AIzaSyCcNHBgzJ5maW7Kf-CMQTLdkl9s8ZV_M3g";
// const calendarId = "starsacramento.org_qca4hlbv93lr62ka7n0ne1l6uc@group.calendar.google.com";

const STARCalendar = () => {
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps) {
      console.info(info.event.extendedProps);
      popoverInstance = new Popover(info.el, {
        title: info.event.extendedProps.description,
        content: info.event.extendedProps.location,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body",
      });

      popoverInstance.show();
    }
  };

  const handleMouseLeave = () => {
    if (popoverInstance) {
      popoverInstance.dispose();
      popoverInstance = null;
    }
  };

  return (
    <CalWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin, interactionPlugin]}
        googleCalendarApiKey="AIzaSyB9Jbwy9kig8ux-bZFown4kopx5eW_Omp0"
        initialView="dayGridMonth"
        // eventColor={ $(theme) => theme.primaryColor }
        eventDisplay="block"
        displayEventTime={true}
        // height= "300px"
        // selectable={true}
        eventMouseEnter={handleMouseEnter}
        eventMouseLeave={handleMouseLeave}
        events={{
          googleCalendarId:
            "starsacramento.org_qca4hlbv93lr62ka7n0ne1l6uc@group.calendar.google.com",
        }}
      />
    </CalWrapper>
  );
};

export default STARCalendar;
